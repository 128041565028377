import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
// import * as Decoder from 'jwt-decode';

export default function Login() {
    document.title = 'Login';
    const nav = useNavigate();


    //TODO: Check if token exists & is valid

  // const handleLogin = async(event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
    
  //   if(![data.get('email'), data.get('password')].every(Boolean)){
  //     alert('Missing information in Form. \nPlease, check input.');
  //     return;
  //   }

  //   const result = await Sessions.Login(data.get('email'), data.get('password'));
    
  //   if(!result.success){
  //     alert('Invalid Credentials');
  //     return;
  //   }

  //   localStorage.setItem('token', JSON.stringify(result.token));
  //   nav('/dashboard');
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            color='secondary'
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            color='secondary'
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color='secondary'
          >
            Sign In
          </Button>
        </Box>
      </Box>
      <hr/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      </Box>
    </Container>
  );
}