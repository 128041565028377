import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../utils';

import { Box, Typography, Divider, Button } from '@mui/material';

export default function Landing() {
    document.title = 'Landing';
    const nav = useNavigate();

    const handleSelection = async(path) => {
        nav(path);
    }

    return (
        <Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'row',
                    width: '100%',
                    height: '100vh',
                    flexWrap: 'wrap',
                    gap: 1,
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                }}
            >
                {Constants.LANDING_ROUTES.map((route) => (
                    <Button 
                        variant='contained' 
                        color='secondary' 
                        sx={{ 
                            borderInlineColor:'#fff', 
                            border:2, 
                            borderRadius:2,
                            width: '30%',
                            height: '15vh'
                        }} 
                        onClick={() => handleSelection(route.path)}
                    >
                        {route.name}
                    </Button>
                ))}
            </Box>
        </Box>
    );
}