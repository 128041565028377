import { Constants } from "../utils";

const GET = async (endpoint, token = '') => {
    let url = `/api/${endpoint}`;
    
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization' : token
   }
  });

  return response.json();
}
  
const POST = async (endpoint, postData, token = '') => {
    let url = `/api/${endpoint}`;
  
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : token
    },
    body: JSON.stringify(postData)
  });

  return response.json();
}

const POST_FILE = async (endpoint, postData, token = '') => {
  let url = `/api/${endpoint}`;

const response = await fetch(url, {
  method: 'POST',
  headers: {
    'Authorization' : token
  },
  body: postData
});

return response.json();
}

const PUT = async (endpoint, postData, token ) => {
    let url = `api${endpoint}`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(postData)
  });

  return response.json();
}

const DELETE = async (endpoint, token) => {
    let url = `api${endpoint}`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': token
    }
  });

  return response.json();
}

export {GET, POST, PUT, DELETE, POST_FILE}