import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Constants } from "./utils";
import * as Components from './components';

const theme = createTheme({
  spacing:8,
  palette: {
    primary: {
      main: Constants.Colors.Primary.Main,
    },
    secondary: {
      main: Constants.Colors.Secondary.Main,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='/' element={<Components.Landing />} />
          <Route path='/tst' element={<Components.Test />} />
          <Route path='/dashboard' element={<Components.Dashboard />} />
          <Route path='/create' element={<Components.Create />} />
          <Route path='/trackstation' element={<Components.TrackStations.Main />} >
            <Route path='' element={<Components.TrackStations.Login />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
