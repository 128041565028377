import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Orders } from '../controllers';
import { useNavigate } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  marginTop: 8
});

export default function Create() {
  document.title = 'Create Order';
  const [label, setLabel] = React.useState('Select File');

  const nav = useNavigate();

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    if(![data.get('reference_id'), data.get('reference_id'), data.get('file')].every(Boolean)){
      alert('Missing information in Form. \nPlease, check input.');
      return;
    }

    var token = JSON.parse(localStorage.getItem('token'));
    const result = await Orders.Create(data, token);
    
    if(!result.success){
      alert(`Unable to process order: \n${result.message}`)
    }

    alert('Order Created Successfully');

    nav('/dashboard');
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Create Order
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="reference_id"
            label="K-Line Reference (###)"
            name="reference_id"
            color='secondary'
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="case_serial"
            label="Candid Id (ABC12)"
            id="case_serial"
            color='secondary'
          />
          <Button
            margin="normal"
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            fullWidth
            color='secondary'
          >
            {label}

            <VisuallyHiddenInput
              type="file"
              name="file"
              id="file"
              onChange={() => setLabel('File Selected. Click to Change')}
            />

          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color='secondary'
          >
            Create Order
          </Button>
       
        </Box>

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => nav('/dashboard')}
        >
          Cancel Operation
        </Button>
      </Box>
      <hr/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      </Box>
    </Container>
  );
}