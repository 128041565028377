import * as HTTP from './http';

export const Create = async(data, token) => {
    let endpoint = `orders/create`;

    try{
        var result = await HTTP.POST_FILE(endpoint, data, token);
    }catch(e){
        console.log(`CONTROLLER ERROR: orders.create\n${e}`);
        return undefined;
    }

    return result;
}

export const List = async (token) => {
    let endpoint = `orders/list`;

    try{
        var result = await HTTP.GET(endpoint, token);
    }catch(e){
        console.log(`CONTROLLER ERROR: orders.list\n${e}`);
        return undefined;
    }

    console.log('totken:', token);

    return result;
}