import React, { useState } from 'react';
import QrScanner from 'react-qr-barcode-scanner';

const BarcodeScanner = () => {
  const [data, setData] = useState('No result'); // Holds the scanned result
  const [isCameraActive, setIsCameraActive] = useState(false); // Toggle camera

  const handleScan = (result) => {
    if (result) {
      setData(result.text); // Extract scanned data
      setIsCameraActive(false); // Stop scanning after successful read
    }
  };

  const handleError = (error) => {
    console.error('Camera error:', error);
  };

  return (
    <div>
      <h1>Barcode Scanner</h1>
      <h2>{data}</h2>
      {isCameraActive ? (
        <QrScanner
          delay={300} // Milliseconds between scans
          onError={handleError}
          onScan={handleScan}
          onUpdate={(err, result) => {
            if (result) {
                setData(result.text);
                setIsCameraActive(false)
            }
            else setData("Not Found");
          }}
          style={{ width: '50%' }}
        />
      ) : (
        <p>Scanning stopped. Scanned Data: {data}</p>
      )}
      <button onClick={() => setIsCameraActive(true)}>Restart Scanner</button>
    </div>
  );
};

export default BarcodeScanner;
