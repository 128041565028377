//Constans used appliaction wide

module.exports = {
    Colors: {
        Primary:{
            Main: '#dc004e'
        },
        Secondary:{
            Main: '#1976d2',
        }
    },
    API: 'http://192.168.100.209:3001/api/',
    LANDING_ROUTES : [
        {
            name: 'Tracking Station',
            path: '/trackstation'
        }
    ]
}