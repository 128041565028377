import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Orders } from '../controllers';

export default function Main() {
    document.title = 'Orders';
    const nav = useNavigate();
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        LoadData();
    },[]);

    const LoadData = async() =>{
      var token = JSON.parse(localStorage.getItem('token'));
      const result = await Orders.List(token);
        
      if(!result.success){
          alert(`Error fetchin data from server. \nPlease, try again`);
          return;
      }
      
      setData(result.orders);
    }
    
  return (
    <Box>
      <Box>
        <Box
          sx={{
            pb: 1,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
            <Typography variant="h6">
              Orders Listing
            </Typography>
            <Box
              sx={{
                gap:2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Tooltip title="Create">
                <IconButton onClick={() => nav('/create')} >
                  <AddCircleIcon color='secondary' fontSize='large'/>
                </IconButton>
              </Tooltip>
            </Box>    
        </Box>    
        
        <Divider orientation='horizontal'></Divider>

        <TableContainer component={Paper} sx={{mt:2}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="center">System ID</TableCell>
                <TableCell align="center">Reference ID</TableCell>
                <TableCell align="center">Case Serial</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.reference_id}</TableCell>
                  <TableCell align="center">{row.case_serial}</TableCell>
                  <TableCell align="center">{row.status.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}